/**
 * Transforms a bid rule form data object into an api bid rule object.
 * @param {Object} form - The form bid rule object.
 *  @param {Integer} campaignsLength - Number of campaigns of the  profile, used to handle allCampaignsSelected
 * @return {Object} the bid rule api object
 */
const formMetricToApi = (form) => {
  const { allCampaignsSelected } = form;
  const apiForm = { ...form };
  if (form.metrics.acorGoal > 20) {
    apiForm.metrics.acorGoal = 20;
  }
  if (form.metrics.salesRoyaltiesPercentage > 100) {
    apiForm.metrics.salesRoyaltiesPercentage = 100;
  }
  const metric = {
    name: apiForm.name,
    campaignIds: apiForm.campaigns.map((campaign) => campaign.campaignId),
    metrics: apiForm.metrics,
    allCampaignsSelected,
  };

  return metric;
};

/**
 * Transforms a bid rule api data object into a form bid rule object.
 * @param {Object} form - The api bid rule object.
 * @return {Object} the bid rule form object
 */
const apiMetricToForm = (metric) => {
  const form = {
    name: metric.name,
    campaigns: metric.campaignIds,
    metrics: metric.metrics,
    allCampaignsSelected: metric.allCampaignsSelected,
  };

  return form;
};

export { formMetricToApi, apiMetricToForm };
